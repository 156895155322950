.message {
  padding: 10px 20px;
  background: #d0d0d0;
  border-radius: 4px;
  margin-bottom: 20px;
}

.contact {
  max-width: $container-width;
  margin-top: 60px;
  display: flex;

  .contactMap {
    position: relative;
    width: calc(50% - 25px);
    height: 600px;
    margin: 0 0 0 25px;

    .contactInfo {
      position: absolute;
      padding: 30px;
      background-color: $white;
      z-index: 1;
      bottom: 0;
      right: 0;

      h2 {
        font-family: $main-font;
        font-weight: 600;
      }

      a {
        text-decoration: none;
        color: $black;
      }
    }

    #map {
      height: 600px;
    }
  }
}

.contactForm {
  width: calc(50% - 25px);
  flex-shrink: 0;
  padding: 0 25px 0 0;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 30px;
  }

  form {
    border: none;
    display: flex;
    flex-direction: column;

    .topInputs {
      display: flex;

      .control {
        width: 50%;
        &:first-child {
          margin-right: 20px;
        }
      }
    }

    .control {
      position: relative;
      margin-bottom: 20px;
      display: inline-block;
      &:first-child {
        margin-right: 5px;
      }

      input,
      textarea {
        background: inherit;
        color: $black;
        border: 1px solid #878787;
        border-radius: 7px;
        padding: 1em 1.8em 0.5em;
        width: 100%;
        transition: all 0.2s ease-out;

        &:focus + label,
        &:not(:placeholder-shown) + label {
          color: $black;
          transform: translateY(-28px);
          background: $white;
        }
      }

      label {
        position: absolute;
        top: 13px;
        left: 20px;
        pointer-events: none;
        color: $gray;
        transition: all 0.2s ease-out;
        padding: 0 6px;
      }
    }
  }
}

@media only screen and (max-width: 912px) {
  .contact {
    margin-top: 0;
    flex-direction: column;

    .contactForm {
      width: 100%;
      margin-bottom: 60px;
    }
    .contactMap {
      width: 100%;
      margin-left: 0;
    }
  }
}
