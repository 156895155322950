.blogItems {
  margin-top: 20px;
  .blogBlockV {
    display: block;
    height: 552px;
    width: 30%;
    transition: 0.2s ease-in-out all;
    margin-right: 34px;
    .ghostButton {
      cursor: pointer;
    }
    &:hover {
      transform: translateY(-4px);
      opacity: 0.9;
    }
    &:last-child {
      margin-right: 0;
    }

    .blogBlockVImg {
      height: 289px;
      width: 100%;
      object-fit: cover;
    }

    .blogBlockVTitle {
      width: 100%;
      margin: 34px 0px 20px 0px;
      font-weight: 700;
      font-size: 20px;
      color: $black;
      letter-spacing: 2px;
    }

    .blogBlockVContent {
      height: 110px;
      color: $black;
      overflow-y: hidden;
      margin-bottom: 33px;
    }
  }

  .blogBlockH {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 380px;
    width: 100%;
    padding: 0 70px;
    margin-top: 90px;
    transition: 0.2s ease-in-out all;

    &:hover {
      transform: translateY(-7px);
      opacity: 0.9;
    }

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }

    &:first-child {
      margin-top: 0px;
    }

    .blogBlockHImg {
      height: 100%;
      width: 650px;
      object-fit: cover;
    }

    button {
      cursor: pointer;
    }

    & > div {
      padding: 70px 35px 70px 35px;
      width: 100%;
      .blogBlockHTitle {
        color: $black;
        width: 100%;
        margin: 34px 0px 20px 0px;
        font-family: $header-font;
        font-weight: 700;
        font-size: 30px;
        letter-spacing: 2px;
      }

      .blogBlockHContent {
        color: $black;
        height: 130px;
        line-height: 40px;
        overflow-y: hidden;
        margin-bottom: 33px;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .blogItems {
    .blogBlockV {
      width: calc(33% - 5px);
      margin-right: 5px;

      // &:last-child {
      //   margin-right: 0;
      // }
    }
  }
}
@media only screen and (max-width: 992px) {
  .blogItems {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1364px;

    .blogBlockV {
      height: 552px;
      width: 100%;
      margin-right: 0px;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0px;
      }

      .blogBlockVTitle {
        width: 100%;
        margin: 34px 0px 0px 0px;
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 2px;
      }

      .blogBlockVContent {
        height: 80px;
        overflow-y: hidden;
        margin-bottom: 10px;
      }
    }

    .blogBlockH {
      flex-direction: column !important;
      height: auto;
      padding: 0px;
      align-items: flex-start;

      .blogBlockHImg {
        height: 300px;
        width: 100%;
      }

      & > div {
        align-items: flex-start;
        padding: 0px;
        .blogBlockHTitle {
          font-size: 25px;
        }

        .blogBlockHContent {
          height: 130px;
          line-height: 40px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
