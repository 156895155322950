.photogrid{
  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  .column {
    flex: 33%;
    max-width: 33%;
    padding: 0 20px;
  
    img {
      margin-top: 8px;
      padding-bottom: 20px;
      vertical-align: middle;
      width: 100%;
    }
  }
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .photogrid{
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .photogrid{
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }
}
