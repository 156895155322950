.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: $container-padding;
}
.main {
  background-color: $white;
  color: $black;
}

.alternative {
  padding: 80px 0%;
  background-color: $black;
  color: $white;
}

.CTA {
  background-color: $lightGray;
  color: $black;
  justify-content: flex-start;

  .CTAFooter {
    width: 80%;
    h1 {
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 30px;
    }
  }
}

.footer {
  background-color: $black;
  color: $white;
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: $container-width;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 60px 20px;
  }

  .CTA {
    .CTAFooter {
      width: 100%;
    }
  }
}
