// Colors
$white: #f4f6f7;
$offWhite: #e4e4e4;
$lightGray: #dcdcdc;
$gray: #878787;
$black: #222222;

//shadow
$shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

// Font familys
$main-font: "Poppins", sans-serif;
$header-font: "Merriweather", serif;

$container-width: 1364px;
$container-padding: 80px 10%;
