.portfolio {
  margin-top: 60px;

  .portfolioBlock {
    display: flex;
    flex-direction: column;
    height: 450px;
    width: calc(50% - 15px);
    margin-right: 30px;
    margin-bottom: 50px;
    box-shadow: $shadow;
    transition: 0.2s ease-in-out all;
    color: $black;
    cursor: pointer;

    &:nth-child(even) {
      margin-right: 0;
    }

    &:hover {
      transform: translateY(-4px);
      opacity: 0.9;
      .portfolioDescription {
        .ghostButton {
          opacity: 1;
        }
      }
    }

    &:nth-child(even) {
      margin-right: 0px;
    }

    .portfolioImg {
      height: 375px;
      width: 100%;
      object-fit: cover;
    }

    .portfolioDescription {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 26px 30px 21px 30px;
      background-color: $offWhite;

      .portfolioTitle {
        font-weight: 600;
      }

      .ghostButton {
        opacity: 0;
        font-size: 16px;
        font-weight: 500;
        transition: 0.3s ease-in-out all;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .portfolioBlock:hover .portfolioDescription .ghostButton {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .portfolioBlock {
      width: 100%;
      margin-right: 0px;

      .portfolioDescription {
        .ghostButton {
          display: none;
        }
      }
    }
  }
}
