.introduction {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1364px;

  .introductionImg {
    width: 45%;
    height: 659px;
  }

  .introductionContent {
    width: calc(44% - 100px);
    margin: 36.5px 0px 0px 100px;
    > h1 {
      font-size: 50px;
      margin-bottom: 57px;
    }

    p {
      margin-bottom: 34px;
    }

    > a > button {
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 908px) {
  .alternative {
    padding-top: 0px;
  }
  .introduction {
    margin-top: -60px;

    .introductionImg {
      width: 100%;
      height: 550px;
    }
    .introductionContent {
      width: 100%;
      margin: 50px 30px;
    }
  }
}
