* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
  background-color: $white;
}

a {
  text-decoration: none;
}

img {
  object-fit: cover;
}

.block {
  margin-bottom: 60px;
}
