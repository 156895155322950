#nav-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0 10%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transition: 0.3s ease-in-out all;

  &.bg {
    background: $white;
    box-shadow: 0 3.9px 5.3px rgba(0, 0, 0, 0.032),
      0 13px 17.9px rgba(0, 0, 0, 0.048), 0 58px 80px rgba(0, 0, 0, 0.08);

    .logo h4 {
      font-size: 14px;
      line-height: 21px;
    }

    * {
      color: $black;
    }

    #menu-wrapper {
      top: 25px;
    }
    #hamburger-menu {
      background: rgba(34, 34, 34, 1);
      &::before,
      &::after {
        background: rgba(34, 34, 34, 1);
      }

      &.animate {
        background: rgba(34, 34, 34, 0);
      }
    }

    nav {
      a,
      span {
        &::after {
          background: $black;
        }
      }
    }
  }
}

nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  a,
  span {
    cursor: pointer;
    font-size: 17px;
    display: inline-block;
    font-weight: 400;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 1px;
      background: $white;
      transition: 0.3s ease-in-out all;
    }

    &:hover::after {
      width: 100%;
    }

    &.active::after {
      width: 100%;
    }
  }

  .logo {
    transition: 0.3s ease-in-out all;

    h4 {
      text-decoration: none;
      color: $white;
      margin: 0;
      transition: 0.3s ease-in-out all;
    }
  }
  .mobile {
    display: none;
    a {
      color: $white;
      text-decoration: none;
      margin-right: 45px;
    }
  }

  #menu {
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    margin: 0;
    padding: 0;
    transition: 0.3s ease-in-out all;
    li {
      color: $white;
      a,
      span {
        color: $white;
        text-decoration: none;
        padding: 10px 15px;
        transition: 0.3s ease-in-out all;
      }
    }
    #dropdown-toggle {
      .dropdown-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        span,
        i {
          display: inline-block;
          justify-content: flex-start;
          transition: 0.3s ease-in-out all;
        }
      }
      .dropdown {
        height: auto;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 0 0 0 5px;
        padding: 10px 15px 10px;
        height: 0;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        a {
          color: $black;
          padding: 10px 0;
          display: inline-block;

          &::after {
            background: $black;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  nav #menu {
    #dropdown-toggle {
      &:hover,
      &:focus,
      &:focus-within {
        .dropdown {
          height: auto;
          background: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  nav {
    .mobile {
      display: block;
    }
    #menu {
      height: 0%;
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100vw;
      z-index: 1;

      * {
        color: $black !important;
      }

      a,
      span {
        display: block;
        padding: 15px;

        &::after {
          background: $black;
        }
      }

      #dropdown-toggle {
        text-align: center;
        display: flex;
        flex-direction: column;

        #dropdown-icon.visible {
          transform: rotate(180deg) scale(var(--ggs, 0.7));
        }

        .dropdown {
          transition: 0.3s ease-in-out all;
          margin: 0;
          padding: 0;
          height: 0;
          overflow: hidden;
          position: relative;
          transition: 0.3s ease-in-out all;

          &.visible {
            margin: 15px 0;
            height: 100%;
          }
        }
      }

      &.visible {
        height: 100%;
      }
    }
  }
}

$bar-width: 22px;
$bar-height: 2px;
$bar-spacing: 6px;

#menu-wrapper {
  position: absolute;
  top: 34px;
  right: 30px;
  margin: auto;
  width: $bar-width;
  height: $bar-height + $bar-spacing * 2;
  cursor: pointer;
  z-index: 2;
  transition: 0.3s ease-in-out all;
}

#hamburger-menu,
#hamburger-menu:after,
#hamburger-menu:before {
  width: $bar-width;
  height: $bar-height;
}

#hamburger-menu {
  position: relative;
  transform: translateY($bar-spacing);
  background: rgba(255, 255, 255, 1);

  &.animate {
    background: rgba(34, 34, 34, 0);
    // background: rgba(34, 34, 34, 0);

    &::before,
    &::after {
      background: rgba(34, 34, 34, 1);
    }
  }
}

#hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: rgba(255, 255, 255, 1);
  // background: rgba(34, 34, 34, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

#hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: rgba(255, 255, 255, 1);
  // background: rgba(34, 34, 34, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

#hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

#hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.7));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}
.gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.gg-facebook {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.7));
  width: 20px;
  height: 20px;
}
.gg-facebook::after,
.gg-facebook::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}
.gg-facebook::before {
  width: 8px;
  height: 20px;
  border-left: 4px solid;
  border-top: 4px solid;
  border-top-left-radius: 5px;
  left: 6px;
}
.gg-facebook::after {
  width: 10px;
  height: 4px;
  background: currentColor;
  top: 7px;
  left: 4px;
  transform: skew(-5deg);
}
.menu-fb {
  display: flex;
  align-items: center;
}
