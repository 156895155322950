.grid {
  .grid-item {
    // width: 25%;
    // position: relative;

    img {
      width: 100%;
      // padding-top: 23.125%;
      // background: gold; /** <-- For the demo **/
    }

    // .container {
    //   background-color: red;
    //   position: relative;
    //   width: 100%;
    //   padding-top: 56.25%; /* 16:9 Aspect Ratio */

    //   img {
    //     // display: block;
    //     // max-width: 100%;
    //   }
    // }
  }

  // .grid-item--width2 {
  //   width: 50%;
  // }
}
