.mainbutton,
input[type="submit"] {
  padding: 0.8em 1em;
  box-shadow: $shadow;
  border: 1px solid $lightGray;
  border-radius: 7px;
  font-family: $main-font;
  font-size: 18px;
  font-weight: 600;
  color: $black;
  background-color: $white;
  transition: 0.3s ease-in-out all;
  cursor: pointer;

  &:hover {
    background-color: $lightGray;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.ghostButton {
  display: flex;
  align-items: center;
  color: $black;
  border: transparent;
  background-color: transparent;
  padding: 0px;
  font-family: $main-font;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;

  &:hover {
    color: $gray;
    transition: 0.4s;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  > svg {
    margin-left: 10px;
  }
}
