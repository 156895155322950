// Font imports
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@700&family=Poppins:wght@300;400;600;700&display=swap");

a,
label,
input,
textarea,
p,
span,
i {
  font-family: $main-font;
  line-height: 30px;
  font-weight: 300;
}

.bold {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font;
  margin-bottom: 14px;
}

.centeredText {
  text-align: left;
  padding: 60px 100px 60px 100px;
  max-width: $container-width;
  > p {
    line-height: 35px;
  }

  &:first-child {
    padding: 0px 100px 60px 100px;
  }
}

@media only screen and (max-width: 768px) {
  .centeredText {
    padding: 0 0 30px;
    &:first-child {
      padding: 0 0 30px;
    }
  }
}
