.bigHeader {
  position: relative;
  width: 100%;
  height: 90vh;
  z-index: 0;

  .headerBackground {
    width: inherit;
    height: inherit;
    position: absolute;

    .headerImg {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-position: center;
      background-size: cover;
    }

    .overlay {
      position: relative;
      width: inherit;
      height: inherit;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  .header {
    position: absolute;
    bottom: 0px;

    .headerContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 1364px;

      h1 {
        color: $white;
        margin-bottom: 41px;
      }
    }
  }
}

.smallHeader {
  position: relative;
  width: 100%;
  height: 40vh;
  z-index: 0;

  .headerBackground {
    width: inherit;
    height: inherit;
    position: absolute;

    .headerImg {
      width: inherit;
      height: inherit;
      object-fit: cover;
      position: absolute;
    }

    .overlay {
      position: relative;
      width: inherit;
      height: inherit;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  .header {
    position: absolute;
    bottom: 0px;
    padding: 50px 10%;

    .headerContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 1364px;

      h1 {
        color: $white;
        margin-bottom: 0px;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .headerContainer {
    left: 9%;

    .headerContent {
      h1 {
        width: 50%;
        word-wrap: wrap;
      }
    }
  }
}
