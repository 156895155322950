.footer {
  .footerItems {
    align-items: flex-start;

    .footerLogo {
      width: calc(46% - 30px);
      margin-right: 30px;
    }

    .footerBlock {
      width: calc(18% - 30px);
      margin-right: 30px;

      .footerTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: normal;
        margin-bottom: 19px;
      }

      p {
        line-height: 30px;
      }

      a {
        text-decoration: underline;
        color: $white;
        display: block;

        &:hover {
          color: $lightGray;
          transition: 0.4s;
        }
      }
    }
  }
}

@media only screen and (max-width: 912px) {
  .footer {
    .footerItems {
      .footerBlock {
        width: calc(50% - 30px);
        margin-bottom: 60px;
        margin-right: 30px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  .footer {
    .footerItems {
      .footerBlock {
        width: 100%;
        margin-bottom: 50px;
        margin-right: 0px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
